import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "pool-head acea-row row-between-wrapper"
};
const _hoisted_2 = {
  class: "fs-18"
};
const _hoisted_3 = {
  class: "fs-18"
};
const _hoisted_4 = {
  class: "pool-title"
};
const _hoisted_5 = {
  class: "contentData"
};
const _hoisted_6 = {
  class: "headers acea-row row-between-wrapper"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "names acea-row row-center-wrapper"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "pool-card__head"
};
const _hoisted_11 = {
  class: "fw-700"
};
const _hoisted_12 = {
  class: "text-center"
};
const _hoisted_13 = {
  class: "text-right"
};
const _hoisted_14 = {
  class: "All_pool_show_dia"
};
const _hoisted_15 = {
  class: "staking-popup"
};
const _hoisted_16 = {
  class: "popup-title"
};
const _hoisted_17 = {
  style: {
    "margin-bottom": "25px",
    "margin-top": "9px"
  }
};
const _hoisted_18 = {
  class: "staking-popup__item"
};
const _hoisted_19 = {
  class: "staking-popup__item"
};
const _hoisted_20 = {
  class: "staking-popup__item"
};
const _hoisted_21 = {
  class: "staking-popup__item"
};
const _hoisted_22 = {
  class: "All_buy_show_dia"
};
const _hoisted_23 = {
  class: "pay-password acea-row row-center-wrapper"
};
import NP from 'number-precision';
import { IMG_URL } from '@/config';
import { computed, onMounted, ref, watch } from 'vue';
import { router } from '@/router';
import { lpList, lpStatistics, stake } from '@/http';
import { showToast } from 'vant';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import PassInput from "@/components/PassInput/PassInput.vue";
export default {
  __name: 'liquidity_pool',
  setup(__props) {
    const userStore = useUserStore();
    const {
      t
    } = useI18n();
    userStore.getAuthState();
    const isAuth = computed(() => userStore.isAuth);
    const list = ref([]);
    const lpData = ref({});
    const showBottom = ref(false);
    const amount = ref('');
    const stakingData = ref({});
    const payPwd = ref('');
    const showPwd = ref(false);
    const showPwdInput = ref(false);
    const success = value => {
      payPwd.value = value;
    };
    const targetElement = ref(null);
    const navBg = ref(false);
    let scrollHandler = null;
    const handleScroll = () => {
      const distanceToTop = window.scrollY;
      if (distanceToTop > 0) {
        navBg.value = true;
      } else {
        navBg.value = false;
      }
    };
    onMounted(() => {
      if (!isAuth.value) {
        showToast(t('please_authenticate_first'));
      }
      scrollHandler = handleScroll;
      window.addEventListener('scroll', scrollHandler);
      handleScroll(); // 初始调用
    });
    const confirmOrder = () => {
      if (!amount.value) {
        return false;
      }
      payPwd.value = '';
      showPwd.value = true;
      showPwdInput.value = true;
    };
    watch(payPwd, newVal => {
      console.log(newVal.length);
      if (newVal.length === 6) {
        stake({
          lp_conf_id: stakingData.value.id,
          amount: amount.value,
          pay_password: newVal
        }).then(({
          msg,
          code
        }) => {
          showToast(msg);
          showPwd.value = false;
          showBottom.value = false;
          if (code === 200) {
            _lpStatistics();
          }
        });
      }
    });
    const _closed = () => {
      payPwd.value = '';
    };
    const staking = item => {
      amount.value = '';
      stakingData.value = item;
      showBottom.value = true;
    };
    const back = () => {
      router.go(-1);
    };
    const _lpList = () => {
      lpList().then(({
        data
      }) => {
        list.value = data.data;
      });
    };
    _lpList();
    const _lpStatistics = () => {
      lpStatistics().then(({
        data
      }) => {
        lpData.value = data;
      });
    };
    _lpStatistics();
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "pool max-846",
        ref_key: "targetElement",
        ref: targetElement
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('current_total_staking')), 1), _createElementVNode("p", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls(lpData.value.amount)) + " ", 1), _cache[4] || (_cache[4] = _createElementVNode("span", null, "USDT", -1))])]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('cumulative_rewards')), 1), _createElementVNode("p", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls(lpData.value.earning)) + " ", 1), _cache[5] || (_cache[5] = _createElementVNode("span", null, "USDT", -1))]), _createElementVNode("p", null, "+" + _toDisplayString(lpData.value.day_earning) + " USDT " + _toDisplayString(_ctx.$t('today')), 1)])]), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('add_liquidity')), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('Name')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('total_revenue')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('daily_yield_rate')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('staking_limit')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('staking_time')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "pool-card acea-row row-between-wrapper",
          key: item.id,
          onClick: $event => staking(item)
        }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
          src: _unref(IMG_URL) + item.img,
          alt: ""
        }, null, 8, _hoisted_9), _createElementVNode("span", null, _toDisplayString(item.name), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(item.rate) + "%", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(_unref(NP).divide(item.rate, item.days).toFixed(2)) + "%", 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("p", null, _toDisplayString(item.min_amount) + " - " + _toDisplayString(item.max_amount), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("p", null, _toDisplayString(item.days) + _toDisplayString(_ctx.$t('day')), 1)])], 8, _hoisted_7);
      }), 128))])], 512), _createVNode(_component_el_dialog, {
        modelValue: showBottom.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showBottom.value = $event),
        width: "448",
        "align-center": "",
        center: "",
        title: _ctx.$t('enter_payment_password'),
        style: {
          "border-radius": "16px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('newPool.StartStaking')), 1), _createVNode(_component_van_field, {
          class: "input-1",
          modelValue: amount.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => amount.value = $event),
          placeholder: _ctx.$t('input_staking_amount'),
          border: false,
          type: "number"
        }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('staking_limit')) + "：" + _toDisplayString(stakingData.value.min_amount) + " - " + _toDisplayString(stakingData.value.max_amount), 1), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('staking_amount')) + "(USDT)", 1), _createElementVNode("span", null, _toDisplayString(amount.value || 0), 1)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('staking_time')), 1), _createElementVNode("span", null, _toDisplayString(stakingData.value.days) + _toDisplayString(_ctx.$t('day')), 1)]), _createElementVNode("div", _hoisted_20, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('daily_yield_rate')), 1), _createElementVNode("span", null, _toDisplayString(stakingData.value.rate) + "%", 1)]), _createElementVNode("div", _hoisted_21, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('total_return_rate')), 1), _createElementVNode("span", null, _toDisplayString(_unref(NP).times(stakingData.value.rate, stakingData.value.days)) + "%", 1)]), _createVNode(_component_van_button, {
          round: "",
          block: "",
          color: "#56F9AA",
          class: _normalizeClass(["noAuths", {
            'isAuths': amount.value
          }]),
          onClick: _cache[1] || (_cache[1] = $event => confirmOrder()),
          disabled: !isAuth.value
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)]),
          _: 1
        }, 8, ["class", "disabled"])])])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_el_dialog, {
        modelValue: showPwd.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showPwd.value = $event),
        width: "448",
        "align-center": "",
        center: "",
        title: _ctx.$t('enter_payment_password'),
        style: {
          "border-radius": "16px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createVNode(PassInput, {
          onSuccess: success
        })])])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }
};